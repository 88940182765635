import { oneOf, string } from "prop-types";
import { memo } from "react";

import HeroDesktopSVG from "~public/images/750/banners/heroBannerDesktop.svg";
import HeroMobileSVG from "~public/images/750/banners/heroBannerMobile.svg";
import classnames from "~utils/classnames";

import classes from "./Banner.module.scss";

const svgs = {
  heroDesktop: HeroDesktopSVG,
  heroMobile: HeroMobileSVG,
};

const ThemeBanner = ({ type, className }) => {
  const SVG = svgs[type];

  return SVG ? <SVG className={classnames(className, classes[type])} /> : null;
};

ThemeBanner.propTypes = {
  type: oneOf(["heroDesktop", "heroMobile"]).isRequired,
  className: string,
};

export default memo(ThemeBanner);
